<template>
  <div>
    <b-form @submit.prevent="submit_search($event)">

      <b-input-group>
        <b-form-input
          v-model="text"
          :placeholder="$t('PAGES.QUICKSEARCH.SEARCH_TERMS')"
        />
        <b-input-group-append>
          <b-button variant="outline-success" type="submit" :disabled="disablebutton">{{$t('COMMON.SEARCH')}}</b-button>
        </b-input-group-append>
      </b-input-group>

    </b-form>
    <div class="mt-2">
      <b-table
        selectable
        select-mode="single"
        id="select-table"
        class="table-striped"
        :fields="fields"
        :items="members"
        head-variant="light"
        ref="select-table"
        @row-clicked="on_row_clicked"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';


import { mapGetters } from 'vuex';
import dayjs from "dayjs";
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'QuickSearchMemberWidget',
  components: {

  },
  emits: ['member_selected'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mixins: [ toasts ],
  data() {
    return {
      members: [],
      disablebutton: false,
      text: '',
      fields: [
        {
          key: 'firstname',
          label: this.$t('MEMBER.FIRSTNAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'lastname',
          label: this.$t('MEMBER.LASTNAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'email',
          label: this.$t('MEMBER.EMAIL'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'phone',
          label: this.$t('MEMBER.PHONE'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],
    };
  },
  async mounted() {

  },
  watch:{

  },
  methods: {
    on_row_clicked(item) {
      this.member_selected(item);
    },
    submit_search(e) {
      e.preventDefault();

      this.concat_search(this.text);
    },
    member_selected(member) {
      this.member_id = member.member_id;
      this.showMemberEditor = true;
      this.$emit('member_selected', member);
      this.scroll_to_class_name('form-create-member');
    },
    async concat_search(str) {
      const loader = this.$loading.show();
      str = str + '';
      str = str.toUpperCase();
      this.disablebutton = true;

      try {
        const res = await axios.post(
          `/member/concat_search`,
          {
            company_id: this.currentCompanyId,
            period_id: this.currentPeriodId,
            str: str,
            all: true
          }
        );

        this.disablebutton = false;

        if (res.status === 200) {
          const members = res.data;
          this.members = members.map(item => {
            item.period = item.pname;
            item.company = item.cname;
            item.rdt = dayjs(item.rdt).format('YYYY-MM-DD')
            return item;
          });
        }
        else {
          this.members = [];
        }

        loader.hide();
      } catch (err) {
        this.disablebutton = false;
        loader.hide();
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.QUICKSEARCH.UNABLE_SEARCH'));
      }
    },
    scroll_to_class_name(classname) {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>

<style lang="scss" scoped>
  :deep .row {
    margin-right: 0;
  }
</style>
