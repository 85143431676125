<template>
  <div class="mt-8" id="page-redeem-code-edit">
    <b-card class="mb-2 mt-8 edit-redeem-code" hide-footer>

        <b-form class="mt-8 ml-0 mr-0 px-0" @submit.prevent="saveForm">

            <p class="mt-4 mb-4 col-lg-6 col-12">
              Länk: <a v-if="url" :href="url">{{url}}</a>
            </p>

            <b-form-group

              id="shop_item_id"
              :label="$t('PAGES.REDEEMCODES.VALID_FOR_ARTICLE')"
              label-for="shop_item_id"
              class="mt-8 col-lg-6 col-12"

            >
              <b-form-select v-model="form.shop_item_id" :options="shop_items_options"></b-form-select>
            </b-form-group>

            <b-form-group

              v-if="form.is_ticket"
              id="event_id"
              :label="$t('PAGES.REDEEMCODES.VALID_FOR_EVENT')"
              label-for="event_id"
              class="mt-8 col-lg-6 col-12"
            >
              <b-form-select v-model="form.event_id" :options="event_options"></b-form-select>
            </b-form-group>

            <b-form-group

              v-if="form.is_membership"
              id="company_name"
              :label="$t('PAGES.REDEEMCODES.MEMBERSHIP_IN_COMPANY')"
              label-for="company_name"
              class="mt-8 col-lg-6 col-12"
            >
              <b-form-input
                  id="input-company_name"
                  v-model="company_name"
                  type="text"
                  disabled
              ></b-form-input>

            </b-form-group>

            <hr/>

            <b-card>

              <p class="mt-8 mb-6 col-lg-6 col-12">{{$t('PAGES.REDEEMCODES.RECIPIENT')}}</p>

              <b-form-group>
                <div class="d-flex align-items-center ml-8">
                  <label class="radio radio-list radio-outline radio-success">
                    <input
                      type="radio"
                      name="member_option"
                      v-model="form.member_option"
                      value="MEMBER"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{$t('PAGES.REDEEMCODES.SEND_TO_MEMBER')}}</span>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="d-flex align-items-center ml-8">
                  <label class="radio radio-list radio-outline radio-success">
                    <input
                      type="radio"
                      name="member_option"
                      v-model="form.member_option"
                      value="INVITE"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{$t('PAGES.REDEEMCODES.CREATE_SMS_EMAIL_INVITE')}}</span>
                </div>
              </b-form-group>

              <div v-if="form.member_option === 'MEMBER'">
                <b-form-group
                  id="member_id"
                  label="Sök och välj medlem"
                  label-for="member_id"
                  class="mt-8"
                  >
                  <QuickSearchMemberWidget
                    @memberSelected="memberSelected"
                  />
                </b-form-group>

                <b-form-group class="col-lg-6 col-12" v-if="selected_member" id="input-group-member_id" :label="$t('PAGES.REDEEMCODES.SELECTED_MEMBER')" label-for="input-header">
                <b-form-input
                    id="input-header"
                    v-model="name"
                    type="text"
                    disabled
                ></b-form-input>
                </b-form-group>
              </div>

              <div v-else-if="form.member_option === 'INVITE'">
                <b-form-group class="col-lg-6 col-12" id="input-group-to_email" :label="$t('PAGES.REDEEMCODES.TO_EMAIL')" label-for="input-to_email">
                <b-form-input
                    id="input-to_email"
                    v-model="form.to_email"
                    type="text"
                    placeholder="exempel@exempel.com"
                    :state="validEmail"
                ></b-form-input>

                </b-form-group>

                <b-form-group class="col-lg-6 col-12" id="input-group-to_phone" :label="$t('PAGES.REDEEMCODES.TO_MOBILE')" label-for="input-to_phone">
                <b-form-input
                    id="input-to_phone"
                    v-model="form.to_phone"
                    type="text"
                    placeholder="+46702233445"
                    :state="validPhone"
                ></b-form-input>
                <b-form-text>{{$t('PAGES.REDEEMCODES.PHONE_INFO')}}</b-form-text>
                </b-form-group>
              </div>

            </b-card>

            <hr/>


            <b-row>
              <b-col lg="6" cols="12">
                <b-form-group class="ml-4" id="input-group-expires_at" :label="$t('PAGES.REDEEMCODES.EXPIRES_AT')" label-for="input-expires_at">
                <b-form-input
                    id="input-expires_at"
                    v-model="form.expires_at"
                    type="text"
                    :state="validExpiresAt"
                ></b-form-input>
                <b-form-text>{{$t('PAGES.REDEEMCODES.DATE_INFO')}}</b-form-text>
                </b-form-group>
              </b-col>
              <b-col class="mt-8 mb-8" lg="6" cols="12">
                 <b-button class="ml-4" variant="outline-success" @click="setNumDays(30)">30 dagar</b-button>
                 <b-button class="ml-4" variant="outline-success" @click="setNumDays(90)">90 dagar</b-button>
                 <b-button class="ml-4" variant="outline-success" @click="setNumDays(365)">1 år</b-button>
              </b-col>
            </b-row>
            <hr/>

            <b-form-group class="col-lg-6 col-12 mt-6" id="input-group-code" label="Kupongkod" label-for="input-code">
            <b-form-input
                id="input-code"
                v-model="form.code"
                type="text"
                disabled
            ></b-form-input>
            <b-form-text>{{$t('PAGES.REDEEMCODES.GENERATED_BY_SYSTEM')}}</b-form-text>
            </b-form-group>

            <div v-if="saveButtonDisable" class="ml-8 alert alert-custom alert-notice alert-light-danger fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">
                  <ul style="margin-left: 16px; margin-bottom: 0px;">
                    <li v-if="form.member_option === 'INVITE' && !validPhone && !validEmail">{{$t('PAGES.REDEEMCODES.MUST_HAVE_EMAIL')}}</li>
                    <li v-if="!form.member_option">{{$t('PAGES.REDEEMCODES.MUST_SELECT_RECIPIENT_TYPE')}}</li>
                    <li v-if="form.member_option === 'MEMBER' && !form.member_id">{{$t('PAGES.REDEEMCODES.MUST_SELECT_MEMBER')}}</li>
                    <li v-if="!form.shop_item_id">{{$t('PAGES.REDEEMCODES.MUST_SELECT_ARTICLE')}}</li>
                    <li v-if="!validExpiresAt">{{$t('PAGES.REDEEMCODES.MUST_SET_EXPIRATION')}}</li>
                  </ul>
                </div>
            </div>

            <b-button :disabled="saveButtonDisable" type="submit" class="ml-4" variant="primary">{{ creating ? $t('COMMON.CREATE') : $t('COMMON.UPDATE') }}</b-button>

        </b-form>

    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import QuickSearchMemberWidget from '@/view/pages/ml/search/QuickSearchMemberWidget.vue';
import { validate_email } from '@/core/services/email';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'redeem_code-editor',
  components: {
    
    QuickSearchMemberWidget
  },
  mixins: [ toasts ],
  props: [],
  emits: ['redeemCodeCreated', 'redeemCodeUpdated'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies']),
    saveButtonDisable() {
      // must return TRUE for invalid!
      // FALSE for valid

      if (!this.form.shop_item_id) {
        return true;
      }

      if (!this.validExpiresAt) {
        return true;
      }

      // if for non member
      if (this.form.member_option === 'INVITE') {
        if (!this.validEmail && !this.validPhone) {
          return true;
        }
      }
      else if (this.form.member_option === 'MEMBER') {
        if (!this.form.member_id) {
          return true;
        }
      }
      else {
        return true;
      }

      return false;
    },
    validEmail() {
      if (!this.form.to_email) { return null; }

      return validate_email(this.form.to_email);
    },
    validPhone() {
      if (!this.form.to_phone) { return null; }
      if (this.form.to_phone.length === 0) {
        return null;
      }

      return this.validatePhone(this.form.to_phone);
    },
    validExpiresAt() {
      if (!this.form.expires_at) {
        return null;
      }

      if (this.form.expires_at.length !== 10) {
        return false;
      }

      return dayjs(this.form.expires_at).isValid();
    }
  },
  mounted() {
    this.getAllShopItems();
  },
  data() {
    return {
      company_name: '',
      url: null,
      shop_items: [],
      shop_items_options: [],
      events: [],
      event_options: [],
      form: {
      },
      name: '',
      selected_member: null,
      editing: false,
      creating: true,
    };
  },
  watch: {

    'form.shop_item_id'(newValue) {

      const shop_item = this.shop_items.find(item => item.shop_item_id === newValue);

      if (!shop_item) {
        console.error('didnt find shop_item', newValue);
      }

      this.form.is_ticket = shop_item.is_ticket;
      this.form.is_membership = shop_item.is_member_fee;

      if (shop_item.is_member_fee) {
        this.form.is_membership = true;
        this.setCompanyName(shop_item.company_id);
        return;
      }

      if (!this.form.is_ticket) {
        return false;
      }

      this.getEventsForShopItem(this.form.shop_item_id)
      .then(events => {
        this.events = events;
        this.event_options = [];

        for (const si of events) {
          this.event_options.push({ value: si.event_id, text: si.name });
        }

      })
      .catch(err => {
        console.error('form.event_id', err);
      })
    }
  },
  methods: {

    setNumDays(days) {
      const exp = dayjs().add(days, 'days').format('YYYY-MM-DD');

      this.form.expires_at = exp;
      // Vue3 compatability for Vue.set
      this.form = { ...this.form };
    },


    setCompanyName(company_id) {
      const company = this.companies.find(item => item.company_id === company_id);
      if (!company) {
        console.error('company not found', company_id);
        return;
      }

      this.company_name = company.name;
    },

    validatePhone(phone) {

      if ((phone+'').length < 11) {
        return false;
      }

      if (phone.substr(0,1) !== '+') {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(phone).toLowerCase());
    },

    memberSelected(member) {

      this.selected_member = member;
      this.form.member_id = member.member_id;
      this.name = this.selected_member.firstname + ' ' + this.selected_member.lastname;
    },

    saveForm() {
      if (this.creating) {
        this.postRedeemCode(this.form)
          .then(res => {
            this.creating = false;
            this.editing = true;
            this.toastr('success', this.$t('COMMON.OK'), 'Kupong skapad');
            this.$emit('redeemCodeCreated', res.data);
          })
          .catch(err => {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Gick inte att skapa kupong');
            console.error('saveForm', err);
          });
      }
      else {
        this.putRedeemCode(this.form)
          .then(res => {
            this.toastr('success', this.$t('COMMON.OK'), 'Kupong uppdaterad');
            this.$emit('redeemCodeUpdated', res.data);
          })
          .catch(err => {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Gick inte att uppdatera kupong');
            console.error('saveForm', err);
          });
      }
    },

    postRedeemCode(data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/redeem_code`, data)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              console.error('status: ' + res.status);
              reject('invalid status');
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });
    },

    putRedeemCode(data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/redeem_code/${id}`, data)
          .then(res => {
            if (res.status === 204) {
              resolve(res.data);
            }
            else {
              console.error('status: ' + res.status);
              reject('invalid status');
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });
    },


    setEditRedeemCode(id) {
      this.getRedeemCode(id)
        .then(redeem_code => {
          this.form = redeem_code;
        })
        .catch(err => {
          console.error('setEditRedeemCode', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta kupong');
        });
    },

    getRedeemCode(id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/redeem_code/${id}`)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              console.error('status: ' + res.status);
              reject('invalid status');
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },

    getEventsForShopItem(shop_item_id) {
      return new Promise ((resolve, reject) => {
        axios
          .get(`/shop_item/events/${shop_item_id}`)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              reject('invalid status');
            }
          })
          .catch(err => {
            console.error(err);
            reject('catched error');
          });
      });
    },

    getAllShopItems() {
      axios
        .get(`/shop_item/all`)
        .then(res => {
          if (res.status === 200) {
            this.shop_items = res.data;
            this.shop_items_options = [];

            for (const si of res.data) {
              this.shop_items_options.push({ value: si.shop_item_id, text: si.name });
            }

          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta butiksartiklar');
        });
    },

    scrollToEdit() {
      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName('edit-event-category')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },

  }
};
</script>
